import React from "react";
import { SiInstagram } from "@react-icons/all-files/si/SiInstagram";
import { SiFacebook } from "@react-icons/all-files/si/SiFacebook";
import { HiOutlineMail } from "@react-icons/all-files/hi/HiOutlineMail";
import map from "../static/contact/28.png";
import nerd from "../static/contact/29.png";
import { Left, Right } from "../components/Common/LeftRight";
import { JoinNewsLetterButton } from "../components/Common/NewsLetterPlug";
import Layout from "../components/layout";
import { SiYoutube } from "@react-icons/all-files/si/SiYoutube";

const Contact = () => (
  <Layout
    title="Wax Carvers - Contact"
    description="You can contact me via email or by messaging @waxcarvers on Instagram. I'm happy to answer any enquiries!"
  >
    <div className="max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <Left
        img={
          <picture>
            <source srcSet={nerd} type="image/webp" />
            <source srcSet={nerd} type="image/jpeg" />
            <img
              className="rounded-lg shadow-lg mx-auto"
              src={nerd}
              alt="Constructing videos"
            />
          </picture>
        }
        noPattern
      >
        <div className="">
          <h1 className="mt-1 text-4xl leading-10 text-blue-700 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-3xl">
            Contact
          </h1>
          <p className="max-w-xl mt-5 prose prose-indigo prose-lg text-gray-700">
            Questions or suggestions? Send me an email!
          </p>
          <div className="max-w-sm ">
            <a href="mailto:info@waxcarvers.com">
              <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <HiOutlineMail size={"2em"} />
                </div>
                <div className="text-center ml-3 text-base leading-6 text-gray-700">
                  <p>info@waxcarvers.com</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </Left>
      <Right
        img={
          <picture>
            <source srcSet={map} type="image/webp" />
            <source srcSet={map} type="image/jpeg" />
            <img
              className="rounded-lg shadow-lg"
              src={map}
              alt="Constructing videos"
            />
          </picture>
        }
      >
        <div>
          <h2 className="text-2xl leading-8 text-blue-700 sm:text-3xl sm:leading-9">
            Location
          </h2>
          <div className="mt-3">
            <p className="prose prose-indigo prose-lg text-gray-700">
              Classes are all online so you can join from wherever you are!{" "}
              <br />
              If you're ever in Arnhem (it's in the Netherlands), let me know!
              I'd love to meet you!
            </p>
          </div>
        </div>
      </Right>
      <Left
        noPattern={true}
        img={
          <div className=" md:mt-0">
            <h2 className="text-2xl leading-8 text-blue-700 sm:text-3xl sm:leading-9">
              Business registration
            </h2>
            <div className="mt-3">
              <p className="text-lg leading-7 text-gray-700"></p>
            </div>
            <div className="prose prose-indigo prose-lg text-gray-700">
              <p>KvK number: 91159202</p>
              <p>BTW number: NL004870620B54</p>
            </div>
          </div>
        }
      >
        <div className=" md:mt-0">
          <h2 className="text-2xl leading-8 text-blue-700 sm:text-3xl sm:leading-9">
            Social Media
          </h2>
          <div className="mt-3">
            <p className="text-lg leading-7 text-gray-700"></p>
          </div>
          <div className="mt-9">
            <a
              href="https://instagram.com/waxcarvers/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className="flex">
                <div className="flex-shrink-0">
                  <SiInstagram size="2em" />
                </div>
                <div className="ml-3 text-base leading-6 text-gray-500">
                  <p>@waxcarvers</p>
                </div>
              </div>
            </a>
            <a
              href="https://www.facebook.com/waxcarvers"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <SiFacebook size="2em" />
                </div>
                <div className="ml-3 text-base leading-6 text-gray-500">
                  <p>Wax Carvers</p>
                </div>
              </div>
            </a>
            <a
              href="https://www.youtube.com/@waxcarvers"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className="mt-6 flex">
                <div className="flex-shrink-0">
                  <SiYoutube size="2em" />
                </div>
                <div className="ml-3 text-base leading-6 text-gray-500">
                  <p>@waxcarvers</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </Left>
    </div>
    <div className="mt-12">
      <JoinNewsLetterButton />
    </div>
  </Layout>
);

export default Contact;
